<template>
  <div class="container">
    <h4 class="my-2">
      Statistics
    </h4>
    <!-- <b-alert
      show
      variant="warning"
      class="my-2"
    >
      <div class="alert-body">
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <img src="@/assets/images/icons/coin.png">
            <span class="ml-1 text-black"><b>2203.975 KWD </b>outstanding payment from your customers</span>
          </div>
          <b-button
            variant="flat-primary"
            @click="handleShowCustomerOutstanding"
          >
            Settle Payments
          </b-button>
        </div>
      </div>
    </b-alert> -->
    <!-- <h5 class="text-black my-1">
      Laundry Now
    </h5> -->
    <b-card class="laundry-now-box border-top-dark border-3">
      <b-row>
        <b-col
          xl="6"
          sm="12"
          lg="6"
          md="6"
          class="d-flex align-items-center card-border-right"
        >
          <div class="d-flex flex-row justify-content-around align-items-start w-100">
            <img src="@/assets/images/icons/rating.png">
            <div class="d-flex flex-column justify-content-between align-items-center w-100">
              <h1 class="text-black">
                {{ statistics.customers }}
              </h1>
              <h5
                class="text-black my-1"
              >
                Total Customers
              </h5>
            </div>
          </div>
        </b-col>
        <b-col
          xl="6"
          sm="12"
          lg="6"
          md="6"
          class="d-flex align-items-center"
        >
          <div class="d-flex flex-row justify-content-around align-items-start w-100">
            <img src="@/assets/images/icons/order.png">
            <div class="d-flex flex-column justify-content-between align-items-center w-100">
              <h1 class="text-black">
                {{ statistics.orders }}
              </h1>
              <h5
                class="text-black my-1"
              >
                Total Orders
              </h5>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <h4 class="my-2">
      Order Statuses Statistics
    </h4>
    <b-row>
      <!-- <b-col
        xl="3"
        sm="12"
        lg="3"
        md="3"
        class="d-flex flex-column justify-content-between align-items-start"
      >
        <b-card class="border-top-dark border-3 w-100">
          <div class="d-flex flex-column justify-content-around align-items-center">
            <h5
              class="text-black my-2 d-flex"
            >
              Customers
            </h5>
            <h1 class="text-black">
              {{ statistics.customers }}
            </h1>
          </div>
        </b-card>
      </b-col>
      <b-col
        xl="3"
        sm="12"
        lg="3"
        md="3"
        class="d-flex flex-column justify-content-between align-items-start"
      >
        <b-card class="border-top-dark border-3 w-100">
          <div class="d-flex flex-column justify-content-around align-items-center">
            <h5
              class="text-black my-2 d-flex"
            >
              Total Orders
            </h5>
            <h1 class="text-black">
              {{ statistics.orders }}
            </h1>
          </div>
        </b-card>
      </b-col> -->
      <b-col
        v-for="(count, status, index) in statistics.statuses_orders_counts"
        :key="index"
        xl="3"
        sm="12"
        lg="3"
        md="3"
        class="d-flex flex-column justify-content-between align-items-start"
      >
        <b-card class="border-top-dark border-3 w-100">
          <div class="d-flex flex-column justify-content-around align-items-center">
            <h1 class="text-black">
              {{ count }}
            </h1>
            <h5
              class="text-black my-2 d-flex"
            >
              {{ status }} Orders
            </h5>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <!-- <b-card class="laundry-now-box border-top-dark border-3">
      <b-row>
        <b-col
          xl="4"
          sm="12"
          lg="4"
          md="4"
          class="d-flex flex-column justify-content-between align-items-start card-border-right"
        >
          <div class="d-flex flex-row justify-content-around align-items-center w-50">
            <img src="@/assets/images/icons/washing-clothes.png">
            <h1 class="text-black">
              {{ statistics.ActiveOrders }}
            </h1>
          </div>
          <h5
            class="text-black my-2 mx-1 d-flex"
          >
            Active Orders
          </h5>
        </b-col>
        <b-col
          xl="4"
          sm="12"
          lg="4"
          md="4"
          class="d-flex flex-column justify-content-between align-items-start card-border-right"
        >
          <div class="d-flex flex-row justify-content-around align-items-center w-50">
            <img src="@/assets/images/icons/delivery-van.png">
            <h1 class="text-black">
              {{ statistics.OnTheWayOrders }}
            </h1>
          </div>
          <h5
            class="text-black my-2 mx-1 d-flex"
          >
            On The Way Orders
          </h5>
        </b-col>
        <b-col
          xl="4"
          sm="12"
          lg="4"
          md="4"
          class="d-flex flex-column justify-content-between align-items-start"
        >
          <div class="d-flex flex-row justify-content-around align-items-center w-50">
            <img src="@/assets/images/icons/online-store.png">
            <h1 class="text-black">
              {{ statistics.DeliveredOrders }}
            </h1>
          </div>
          <h5 class="text-black my-2 mx-1 d-flex">
            Delivered Orders
          </h5>
        </b-col>
      </b-row>
    </b-card> -->

    <!-- For Modal Show -->
    <!-- <h5
      class="text-black my-2 mx-1 d-flex"
      @click="handleShowActiveOrders"
    >
      Active Orders
      <b-button
        class="p-25 bg-primary bg-darken-2 text-white rounded-circle d-flex mx-50"
        variant="none"
      >
        <feather-icon
          icon="ChevronRightIcon"
        />
      </b-button>
    </h5> -->

    <!-- <h5 class="text-black my-1">
      Laundry Performance
    </h5>

    <b-row>
      <b-col
        xl="3"
        sm="12"
        class="d-flex flex-column justify-content-between align-items-start "
      >
        <b-card class="shadow-none bg-transparent border-secondary w-100">
          <div class="d-flex flex-row align-items-start">
            <img src="@/assets/images/icons/add-file.png">
            <div class="d-flex flex-column mx-1">
              <h5>
                Placed
              </h5>
              <h2 class="text-black">
                {{ statistics.Placed }}
              </h2>
            </div>
          </div>
        </b-card>
      </b-col>
      <b-col
        xl="3"
        sm="12"
        class="d-flex flex-column justify-content-between align-items-start"
      >
        <b-card class="shadow-none bg-transparent border-secondary w-100">
          <div class="d-flex flex-row align-items-center">
            <img src="@/assets/images/icons/confirm.png">
            <div class="d-flex flex-column mx-1">
              <h5>
                Completed
              </h5>
              <h2 class="text-black">
                {{ statistics.Completed }}
              </h2>
            </div>
          </div>
        </b-card>
      </b-col>
      <b-col
        xl="3"
        sm="12"
        class="d-flex flex-column justify-content-between align-items-start"
      >
        <b-card class="shadow-none bg-transparent border-secondary w-100">
          <div class="d-flex flex-row align-items-center">
            <img src="@/assets/images/icons/discount.png">
            <div class="d-flex flex-column mx-1">
              <h5>
                Sales
              </h5>
              <h4 class="text-black">
                {{ statistics.Sales }} KWD
              </h4>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row> -->
    <!-- <customer-outstanding v-if="customerOutstandingShown" />
    <pickup-and-delivery v-if="pickupAndDeliveryShown" />
    <active-orders v-if="activeOrdersShown" /> -->
  </div>
</template>

<script>
import {
  BCard,
  // BCardText,
  // BAlert,
  BRow,
  BCol,
  // BButton,
} from 'bootstrap-vue'
import { onMounted, ref } from '@vue/composition-api'
import axios from 'axios'

// import CustomerOutstanding from '@/layouts/components/customerOutstanding/CustomerOutstanding.vue'
// import PickupAndDelivery from '@/layouts/components/pickupAndDelivery/PickupAndDelivery.vue'
// import ActiveOrders from '@/layouts/components/activeOrders/ActiveOrders.vue'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    // BCardText,
    // BAlert,
    BRow,
    BCol,
    // CustomerOutstanding,
    // BButton,
    // PickupAndDelivery,
    // ActiveOrders,
  },
  setup(props, context) {
    const toast = useToast()
    const activeOrdersShown = ref(false)
    const pickupAndDeliveryShown = ref(false)
    const customerOutstandingShown = ref(false)
    const userName = JSON.parse(localStorage.getItem('user')).full_name
    const statistics = ref({})

    const handleShowActiveOrders = () => {
      activeOrdersShown.value = true

      setTimeout(() => {
        context.root.$emit('bv::show::modal', 'active-orders')
      }, 50)
    }

    const handleShowPickupAndDelivery = () => {
      pickupAndDeliveryShown.value = true

      setTimeout(() => {
        context.root.$emit('bv::show::modal', 'pickup-delivery')
      }, 50)
    }

    const handleShowCustomerOutstanding = () => {
      customerOutstandingShown.value = true

      setTimeout(() => {
        context.root.$emit('bv::show::modal', 'customer-outstanding')
      }, 50)
    }

    const getStatistics = () => {
      axios.get(`${process.env.VUE_APP_BASE_API_URL}/pos-statistics`).then(res => {
        if (res.data.code === 200) {
          statistics.value = res.data.data
        }
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching statistics',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    }

    onMounted(() => {
      getStatistics()
    })

    return {
      userName,
      activeOrdersShown,
      pickupAndDeliveryShown,
      customerOutstandingShown,
      handleShowActiveOrders,
      handleShowPickupAndDelivery,
      handleShowCustomerOutstanding,
      statistics,
    }
  },
}
</script>

<style>

</style>
